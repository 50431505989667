import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';

export default {
  name: 'KnFormReportCardDesign',
  components: {
    KnBackToTopButton,
    KnFormNoteOfMandatory,
    KnFormTitle,
    KnLocalAlert,
    KnTabs,
  },
  mixins: [],
  props: {},
  data() {
    return {
      tabs: [{ name: 'Personalización', value: 100 }],
      valueDeterminate: 50,
      routerName: 'Boletas',
      resource: 'materiasprofesor',
      bkEntity: null,
      bkReadonly: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
    };
  },
  computed: {},
  created() {},
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val;
    },
  },
};
